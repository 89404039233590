import '../css'

import { initDymo, withLabelPrinter, renderLabel, printLabel } from './dymo'
import { Stimulus, Controller } from '#/application/js/stimulus'

Stimulus.register('label', class extends Controller {
  static targets = ['container']

  connect() {
    initDymo()
  }

  containerTargetConnected(el) {
    withLabelPrinter(() => {
      const imageData = renderLabel({
        address: el.getAttribute('data-address'),
        barcode: el.getAttribute('data-barcode')
      })

      if (imageData) {
        el.innerHTML = `
          <img src="data:image/png;base64,${imageData}"/>
          <i class="fas fa-print fa-lg" data-controller="label" data-action="click->label#print"></i>
        `
      }
    })
  }

  print(e) {
    withLabelPrinter(() => {
      printLabel({
        address: e.currentTarget.parentElement.getAttribute('data-address'),
        barcode: e.currentTarget.parentElement.getAttribute('data-barcode')
      })
    })
  }
})
