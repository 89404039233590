import {
  faArrowDown,
  faArrowDownAZ,
  faArrowDownWideShort,
  faArrowUp,
  faArrowUpAZ,
  faArrowUpWideShort,
  faBellConcierge,
  faBook,
  faBuilding,
  faBullhorn,
  faCalendar,
  faCalendarPlus,
  faCaretRight,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faClipboardList,
  faDownload,
  faDownLong,
  faEnvelope,
  faEnvelopeOpenText,
  faEraser,
  faFax,
  faFilePdf,
  faHandHolding,
  faHouse,
  faHouseUser,
  faLandmark,
  faListUl,
  faLocationDot,
  faMapLocationDot,
  faMaximize,
  faMobileScreenButton,
  faPencil,
  faPrint,
  faRightLong,
  faShare,
  faStar,
  faStreetView,
  faTrashCan,
  faTriangleExclamation,
  faUpLong,
  faUser,
  faUserGear,
  faUsers,
  faUsersGear,
  faUserTag,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

import {
  faObjectGroup as farObjectGroup,
  faStar as farStar,
} from '@fortawesome/free-regular-svg-icons'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
library.add(
  faArrowDown,
  faArrowDownAZ,
  faArrowDownWideShort,
  faArrowUp,
  faArrowUpAZ,
  faArrowUpWideShort,
  faBellConcierge,
  faBook,
  faBuilding,
  faBullhorn,
  faCalendar,
  faCalendarPlus,
  faCaretRight,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faClipboardList,
  faDownload,
  faDownLong,
  faEnvelope,
  faEnvelopeOpenText,
  faEraser,
  faFax,
  faFilePdf,
  faHandHolding,
  faHouse,
  faHouseUser,
  faLandmark,
  faListUl,
  faLocationDot,
  faMapLocationDot,
  faMaximize,
  faMobileScreenButton,
  faPencil,
  faPrint,
  faRightLong,
  faShare,
  faStar,
  faStreetView,
  faTrashCan,
  faTriangleExclamation,
  faUpLong,
  faUser,
  faUserGear,
  faUsers,
  faUsersGear,
  faUserTag,
  faXmark,
  farObjectGroup,
  farStar,
)
dom.watch()
