const calculateWidth = function(fontName) {
  const span = document.createElement('span')
  span.style = `position: absolute!important; left: -99999px!important; width: auto!important; font-size: 128px!important; font-family: "${fontName}",serif!important;`
  span.innerText = Array(10).join('FTDA')
  document.body.appendChild(span)
  const width = span.clientWidth
  document.body.removeChild(span)

  return width
}

const fontList = {}
let fontWidth

const fontIsAvailable = function(fontName) {
  if (fontList[fontName]) return true

  fontWidth = fontWidth ? fontWidth : calculateWidth('serif')
  fontList[fontName] = fontWidth !== calculateWidth(fontName)
}

let addressLabelFont
const getAddressLabelFont = function() {
  if (addressLabelFont) return addressLabelFont

  const fonts = [
    'Menlo',
    'Monaco',
    'Consolas',
    'Courier',
    'Courier New',
    'Lucida Console'
  ]
  for (const font of fonts) {
    if (fontIsAvailable(font)) {
      addressLabelFont = font
      return font
    }
  }
  return false
}

let barcodeFont
const getBarcodeFont = function() {
  if (barcodeFont) return barcodeFont

  const fonts = [
    'USPSIMBStandard',
    'USPSIMBStandard Standard'
  ]
  for (const font of fonts) {
    if (fontIsAvailable(font)) {
      barcodeFont = font
      return font
    }
  }
  return false
}

const canPrintLabels = function() {
  const { isBrowserSupported, isFrameworkInstalled, isWebServicePresent } = dymo.label.framework.checkEnvironment()
  return isBrowserSupported && isFrameworkInstalled && isWebServicePresent
}

const getLabelPrinterName = function() {
  for (const printer of dymo.label.framework.getPrinters()) {
    if (printer.printerType === 'LabelWriterPrinter')
      return printer.name
  }
  return false
}

const barcodeLabel = (font, barcodeFont) => `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>Address</Id>
    <PaperName>30252 Address</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270"/>
    </DrawCommands>
    <ObjectInfo>
      <TextObject>
        <Name>ADDRESS</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>ShrinkToFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>NULL</String>
            <Attributes>
              <Font Family="${font}" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="331.2" Y="86.39999" Width="4382.764" Height="1098.209"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>BARCODE</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Bottom</VerticalAlignment>
        <TextFitMode>None</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>FDAT</String>
            <Attributes>
              <Font Family="${barcodeFont}" Size="16" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="331.2" Y="986.9426" Width="4377.6" Height="505.8574"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>CONTEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Right</HorizontalAlignment>
        <VerticalAlignment>Top</VerticalAlignment>
        <TextFitMode>ShrinkToFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String> </String>
            <Attributes>
              <Font Family="${font}" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="2020.77" Y="57.59995" Width="2932.83" Height="227.3759"/>
    </ObjectInfo>
  </DieCutLabel>`

const defaultLabel = (font) => `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>Address</Id>
    <PaperName>30252 Address</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270"/>
    </DrawCommands>
    <ObjectInfo>
      <TextObject>
        <Name>ADDRESS</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>ShrinkToFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>NULL</String>
            <Attributes>
              <Font Family="${font}" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="331.2" Y="149.4868" Width="4382.764" Height="1343.313"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>CONTEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Right</HorizontalAlignment>
        <VerticalAlignment>Top</VerticalAlignment>
        <TextFitMode>ShrinkToFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String> </String>
            <Attributes>
              <Font Family="${font}" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="2020.77" Y="57.59995" Width="2932.83" Height="227.3759"/>
    </ObjectInfo>
  </DieCutLabel>`

const getAddressLabel = function(with_barcode) {
  const labelFont = getAddressLabelFont() || 'Courier'
  let labelXml = with_barcode ? barcodeLabel(labelFont, getBarcodeFont()) : defaultLabel(labelFont)

  return dymo.label.framework.openLabelXml(labelXml)
}

const fontsReady = () => getAddressLabelFont() && getBarcodeFont()
const labelPrinterReady = () => dymo.label.framework.checkEnvironment().isFrameworkInstalled && getLabelPrinterName()

let dymoIsReady = false
const LabelHooks = []

const initDymo = function() {
  dymo.label.framework.init(() => {
    dymoIsReady = true
    for (const func of LabelHooks) {
      func()
    }
  })
}

const withLabelPrinter = function(func) {
  let count = 0
  let timeout

  // Attempt to check the fonts four times before bothering with the label printer
  const checkReady = () => {
    if (count == 4 || fontsReady()) {
      clearTimeout(timeout)

      if (dymoIsReady) {
        labelPrinterReady() && func()
      } else {
        LabelHooks.push(() => labelPrinterReady() && func())
      }
    } else {
      count++
      timeout = setTimeout(checkReady, 100)
    }
  }

  checkReady()
}

const printLabel = function(address) {
  const printerName = getLabelPrinterName()
  if (!(canPrintLabels() && printerName))
    return false

  const labelSet = new dymo.label.framework.LabelSetBuilder()
  const record = labelSet.addRecord()
  record.setText('ADDRESS', address.address)
  address.barcode && record.setText('BARCODE', address.barcode)
  address.context && record.setText('CONTEXT', address.context)

  const printParamsXml = dymo.label.framework.createLabelWriterPrintParamsXml({
    copies: 1,
    jobTitle: 'Ministers',
    flowDirection: 'LeftToRight',
    printQuality: 'Auto',
    twinTurboRoll: 'Auto',
  })

  const label = getAddressLabel(address.barcode)
  label.print(printerName, printParamsXml, labelSet.toString())

  return true
}

const renderLabel = function(address) {
  const printerName = getLabelPrinterName()
  if (!(canPrintLabels() && printerName))
    return false

  const renderParamsXml = dymo.label.framework.createLabelRenderParamsXml({
    shadowDepth: 0,
    pngUseDisplayResolution: false
  })

  const label = getAddressLabel(address.barcode)
  label.setObjectText('ADDRESS', address.address)
  address.barcode && label.setObjectText('BARCODE', address.barcode)
  address.context && label.setObjectText('CONTEXT', address.context)

  return label.render(renderParamsXml, printerName)
}

export { initDymo, withLabelPrinter, printLabel, renderLabel }
