import ujs from '@rails/ujs'

$(document).on('click', '#clearSearch', function(e) {
  e.preventDefault()
  $('#q').val('').trigger('focus')
  ujs.fire($(this).parents('form')[0], 'submit')
})

$(function() {
  let form = document.querySelector('form.search-on-load')
  if (form) {
    ujs.fire(form, 'submit')
    $(form).find('input[name="page"]').remove() // Remove saved page number so it doesn't pollute subsequent searches
  }
})
