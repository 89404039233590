import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer, SuperClusterAlgorithm, NoopAlgorithm } from '@googlemaps/markerclusterer'

class CustomNoopAlgorithm extends NoopAlgorithm {
  calculate({ markers, map, mapCanvasProjection }) {
    return {
      clusters: this.cluster({ markers, map, mapCanvasProjection }),
      changed: true
    }
  }
}

let algorithm = function() {
  let noop = document.getElementById('disable_clustering').checked
  return noop ? new CustomNoopAlgorithm() : new SuperClusterAlgorithm({ radius: 60 })
}

let clusterer

$(document).on('change', '#disable_clustering', function() {
  clusterer.algorithm = algorithm()
  clusterer.render()

  document.cookie = `disable_clustering=${this.checked}; Max-Age=31536000; Path=/`
})

$(function() {
  const mapEl = document.getElementById('map')
  if (mapEl) {
    const loader = new Loader({ apiKey: mapEl.getAttribute('data-api-key'), version: 'quarterly' })

    Promise.all([
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
      loader.importLibrary('core')
    ]).then(([{ Map, InfoWindow }, { Marker }, { LatLngBounds }]) => {
      const map = new Map(mapEl)
      const json = JSON.parse(document.getElementById('mapData').innerText)
      const bounds = new LatLngBounds()

      const markers = json.map(({ position, icon, infowindow }) => {
        let marker = new Marker({ position, icon, map })

        marker.addListener('click', () => {
          new InfoWindow({ content: infowindow }).open({ anchor: marker, map, shouldFocus: false })
        })

        bounds.extend(marker.getPosition())
        return marker
      })

      map.fitBounds(bounds)
      clusterer = new MarkerClusterer({ map, markers, algorithm: algorithm() })
    })
  }
})
