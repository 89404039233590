import ujs from '@rails/ujs'
ujs.start()

import './globals/jquery'
import './globals/moment'
import './globals/popper'

import '@nathanvda/cocoon'

import 'bootstrap'

import '#/application/js'
import '#/admin/ministers/js'
import '#/admin/notices/js'
import '#/dashboard/js'
import '#/halls/js'
import '#/literature/js'
import '#/meeting_halls/halls/js'
import '#/members/js'
import '#/rd/reports/js'
import '#/uploads/js'
import '#/visit_requests/visit_requests/js'
