const validate = function() {
  var invalid =
    $('#event_type').val() == '' ||
    $('#event_at_text').val() == '' ||
    ($('#invited_text').val() == '' && $('#extra_info_div').is(':visible'))

  $('#submit_button_event_form').attr('disabled', invalid)
}

$(document).on('change', '#assign_to_minister_id', function() {
  $('#submit_button_assign_form').attr('disabled', $(this).val() == '')
})

// Change event form
$(document).on('change', '#event_type', function() {
  if ($(this).val() == 'invited') {
    $('#extra_info_div').show()
  } else {
    $('#invited_text').val('')
    $('#extra_info_div').hide()
  }

  validate()
})

$(document).on('change.datetimepicker', 'div[id^="datepicker-event_at_text"]', validate)
$(document).on('change keyup', '#event_at_text', validate)
$(document).on('keyup', '#invited_text', validate)

// Update request form
$(document).on('keyup', '#update_request_text', function() {
  $('#submit_button_update_request_form').attr('disabled', $(this).val() == '')
})

$(document).on('click', '#submit_sort_and_filter_button', function(e) {
  e.preventDefault()

  $(this).addClass('disabled').html('Sending...')
  window.location = '/visit_requests/open/?' + $.param({
    filter: $('#filter').val(),
    minister_id: $(this).attr('data-minister')
  })
})
