function setCountryDivisionList(prefix, currentId) {
  var el = $(`#${prefix}_country_division_id`)
  el.empty()

  $.get('/address_verification/country_division_select/' + $(`#${prefix}_country_id`).val(), function(data) {
    el.html(data).val(currentId).trigger('chosen:updated')
  })
}

$(document).on('change', '#hall_country_id', function() {
  setCountryDivisionList('hall')
})

$(document).on('change', '#hall_billing_country_id', function() {
  setCountryDivisionList('hall_billing')
})

$(document).on('click', '.set-lat-long', function() {
  $('#hall_latitude, #hall_longitude').empty()

  $.get('/address_verification/get_latitude_and_longitude/?' + $.param({
    address: $('#hall_address').val(),
    locality: $('#hall_locality').val(),
    country_division_id: $('#hall_country_division_id').val(),
    postal_code: $('#hall_postal_code').val(),
    country_id:$('#hall_country_id').val()
  }), function(data) {
    $('#hall_latitude').val(data['latitude'])
    $('#hall_longitude').val(data['longitude'])
  })
})

$(document).on('click', '.copy-billing-info', function() {
  $('#hall_billing_country_id').val($('#hall_country_id').val()).trigger('chosen:updated')
  setCountryDivisionList('hall_billing', $('#hall_country_division_id').val());

  ['name', 'address', 'locality', 'postal_code'].forEach(function(field) {
    $(`#hall_billing_${field}`).val($(`hall_${field}`).val())
  })
})

$(document).on('change', '#hall_billing_type', function() {
  $('#billing_address').toggleClass('d-none', $(this).val() != 'direct_billing')

  if ($(this).val() == 'direct_billing' && $('#hall_billing_country_id').val() == '') {
    $('#hall_billing_country_id').val($('#hall_country_id').val()).trigger('chosen:updated')
    setCountryDivisionList('hall_billing')
  }
})

$(function() {
  ['hall', 'hall_billing'].forEach(function(prefix) {
    var el = $(`#${prefix}_country_id`)

    if (el.length > 0 && el.val() != '')
      setCountryDivisionList(prefix, el.attr('data-country-division'))
  })
})
