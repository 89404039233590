$(document).on('click', '.add_minister', function() {
  $('#newMinisterModal').modal('show')
})

$(document).on('hide.bs.modal', '#newMinisterModal', function() {
  $(this).find('.modalContent').val('')
  $(this).find('.createMinisterErrorMessage').html('').removeClass('alert alert-danger')
})

$(document).on('shown.bs.modal', '#newMinisterModal', function() {
  $('.modalContent').trigger('focus')
})

$(document).on('click', '#minister_limit_to_congregations', function() {
  $('.region-div').toggleClass('d-none', $(this).is(':checked'))
})
