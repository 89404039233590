import '../css'
import Masonry from 'masonry-layout'

$(function() {
  var el = document.querySelector('.grid')

  if (el) {
    new Masonry(el, {
      itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
      columnWidth: '.grid-sizer',
      percentPosition: true
    })
  }
})
