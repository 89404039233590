$(document).on('click', '.add_to_list', function() {
  $('#region_id').val($(this).attr('data-region-id'))
  $('#update_type').val('add')
  $('#update_list_modal').modal('show')
})

$(document).on('click', '.remove_from_list', function() {
  $('#region_id').val($(this).attr('data-region-id'))
  $('#name').val($(this).attr('data-name'))
  $('#location').val($(this).attr('data-location'))
  $('#subscription_type').val('Please remove from the following lists: ' + $(this).attr('data-subscription-types'))
  $('#update_type').val('remove')
  $('#update_list_modal').modal('show')
})

$(document).on('hide.bs.modal', '#update_list_modal', function() {
  $(this).find('.modalContent').val('')
  $(this).find('#region_id').val('')
  $(this).find('#errorMessage').html('').hide()
})
