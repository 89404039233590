import '../css/bootstrap-theme'
import '../css/print'
import './chosen'
import './datetimepicker'
import './fontawesome'
import './map'
import './minicolors'
import './search'

$(document).on('click', '.toggle-header', function() {
  $(`.toggle-section-${$(this).attr('data-id')}`).toggleClass('d-none')
})

$(document).on('change', '#gps_type', function() {
  $('.instruction').hide()
  $('#' + $('#gps_type').val()).show()
})
